import React, { useEffect }  from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/ComputerConnection.scss';

const ComputerConnection = () => {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    },);

    return (
        <section className='connection'>
            <div className="connection-content wrapper">
                <h3>{t("connection")}</h3>
                <div className="connection-content__main">
                    <div className="connection-content__txt">
                        <p>{t("connectionTxt")}</p>
                    </div>
                    <div className="connection-content__img">
                        <img src={process.env.PUBLIC_URL + "./pcConnecting/1.webp"} alt="image" />
                        <p>{t("connectionImg")}</p>
                    </div>
                </div>
                <div className="connection-content__desc">
                    <p>{t("connectionDesc.1")}</p>
                    <p>{t("connectionDesc.2")}</p>
                    <p>{t("connectionDesc.3")}</p>
                </div>
                <div className="connection-content__list">
                    <h4>{t("connectionList.1")}</h4>
                    <ul>
                        <li>{t("connectionList.2")}</li>
                        <li>{t("connectionList.3")}</li>
                        <li>{t("connectionList.4")}</li>
                        <li>{t("connectionList.5")}</li>
                    </ul>
                </div>
                <div className="connection-content__connected">
                    <div className="connection-content__pic">
                        <img src={process.env.PUBLIC_URL + "./pcConnecting/2.webp"} alt="image" />
                        <p>{t("connectionPic")}</p>
                    </div>
                    <div className="connection-content__info">
                        <p>{t("connectionInfo")}</p>
                    </div>
                </div>
                <div className="connection-content__text">
                    <p>{t("connectionText.1")}</p>
                    <p>{t("connectionText.2")}</p>
                </div>
            </div>
        </section>
    );
};

export default ComputerConnection;