import React, { useEffect }  from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/SensorSetup.scss';

interface Row {
    col1: string;
    col2: string;
}

const SensorSetup = () => {
    const { t } = useTranslation();
    const rows: Row[] = t('table1.rows', { returnObjects: true }) as Row[];

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/SensorView.zip`;
        link.setAttribute('download', '/SensorView.zip');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    },);

    const setupData = [
        {
            id: 0,
            img: "/setup/1.webp",
            subTxt: t("setup1Img"),
            txt: t("setup1Desc"),
        },
        {
            id: 1,
            img: "/setup/2.webp",
            subTxt: t("setup2Img"),
            txt: t("setup2Desc")
        },
        {
            id: 2,
            img: "/setup/3.webp",
            subTxt: t("setup3Img"),
            txt: t("setup3Desc")
        },
        {
            id: 3,
            img: "/setup/4.webp",
            subTxt: t("setup4Img"),
            txt: t("setup4Desc"),
            otherTxt: t("setup4Sub")
        },
        {
            id: 4,
            img: "/setup/5.webp",
            subTxt: t("setup5Img"),
            txt: t("setup5Desc")
        },
    ]

    return (
        <section className='setup'>
            <div className="setup-main">
                <div className="setup-main__table wrapper">
                    <h3 className="setup-main__title">{t("sensorSetupTableTitle")}</h3>
                    <table>
                        <tbody>
                            {rows.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.col1}</td>
                                    <td>{row.col2}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <p>{t("sensorSetupTableSub")}</p>
                </div>
            </div>
            <div className="setup-txt wrapper">
                <h3 className="setup-txt__title">{t("setupTitle")}</h3>
                <p className="setup-txt__desc">{t("setupDesc")}</p>
                <button onClick={handleDownload} className='btn'>
                    <span>{t("setupArchive")}</span>
                    <img src={process.env.PUBLIC_URL + "/icons/arrowRight.svg"} alt="read more" />
                </button>
            </div>
            {setupData.map((el, index) => (
                <div className="setup-instruction" key={index}>
                    <div className="setup-instructions wrapper">
                        <div className="setup-instructions__content">
                            <div className="setup-instructions__img">
                                <img src={process.env.PUBLIC_URL + el.img} alt="Screen image" />
                                <p>{el.subTxt}</p>
                            </div>
                            <div className="setup-instructions__txt">
                                <p>{el.txt}</p>
                            </div>
                        </div>
                        {el.otherTxt && (
                            <div className="setup-instructions__sub">
                                <p>{el.otherTxt}</p>
                            </div>
                        )}
                    </div>
                </div>
            ))}
            <div className="setup-twoFr wrapper">
                <div className="setup-twoFr__txt">
                    <div className="setup-twoFr__title">
                        <h4>{t("setupListTitle")}</h4>
                    </div>
                    <div className="setup-twoFr__list">
                        <ul>
                            <li>{t("setupList.1")}</li>
                            <li>{t("setupList.2")}</li>
                            <li>{t("setupList.3")}</li>
                            <li>{t("setupList.4")}</li>
                            <li>{t("setupList.5")}</li>
                        </ul>
                    </div>
                </div>
                <div className="setup-twoFr__warning">
                    <p><span>{t("warningSpan")}</span>{t("setupWarning")}</p>
                </div>
            </div>
            <div className="setup-list wrapper">
                <ul className="setup-list__ul">
                    <li className="setup-list__li">{t("setupList2.1")}</li>
                    <li className="setup-list__li">{t("setupList2.2")}</li>
                    <li className="setup-list__li">{t("setupList2.3")}</li>
                    <li className="setup-list__li">{t("setupList2.4")}</li>
                    <li className="setup-list__li">{t("setupList2.5")}</li>
                    <li className="setup-list__li">{t("setupList2.6")}</li>
                    <li className="setup-list__li">{t("setupList2.7")}</li>
                    <li className="setup-list__li">{t("setupList2.8")}</li>
                </ul>
            </div>
        </section>
    );
};

export default SensorSetup;