import React, { useEffect }  from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/SensorProgramming.scss';
import CodeBlock from './UI/CodeBlock';

const SensorProgramming = () => {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    },);

    return (
        <section className='programm'>
            <div className="programm-content wrapper">
                <h3>{t("step3")}</h3>
                <div className="programm-content__table-one">
                    <h4>Table RS-422 and RS-485 Interface Standards</h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Specification</th>
                                <th>RS-422</th>
                                <th>RS-485</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Transmission Type</td>
                                <td>Differential</td>
                                <td>Differential</td>
                            </tr>
                            <tr>
                                <td>Maximum Cable Length</td>
                                <td>4000 ft</td>
                                <td>4000 ft</td>
                            </tr>
                            <tr>
                                <td>Minimum Driver Output Voltage</td>
                                <td>±2 V</td>
                                <td>±1.5 V</td>
                            </tr>
                            <tr>
                                <td>Driver Load Impedance</td>
                                <td>100 Ω</td>
                                <td>54 Ω</td>
                            </tr>
                            <tr>
                                <td>Receiver Input Resistance</td>
                                <td>4 kΩ min</td>
                                <td>12 kΩ min</td>
                            </tr>
                            <tr>
                                <td>Receiver Input Sensitivity</td>
                                <td>± 200 mV</td>
                                <td>± 200 mV</td>
                            </tr>
                            <tr>
                                <td>Receiver Input Range</td>
                                <td>-7 V to +7 V</td>
                                <td>-7 V to +12 V</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="programm-content__draft">
                    <img src={process.env.PUBLIC_URL + "/trans.webp"} alt="" />
                </div>
                <div className="programm-content__table-two">
                    <h4>{t("programmingT2")}</h4>
                    <table>
                        <tbody>
                            <tr>
                                <td>Mode:</td>
                                <td>Asynchronous</td>
                            </tr>
                            <tr>
                                <td>Baud Rate</td>
                                <td>115200 Bits/s</td>
                            </tr>
                            <tr>
                                <td>Word Length</td>
                                <td>8 Bits (including Parity)</td>
                            </tr>
                            <tr>
                                <td>Parity</td>
                                <td>None</td>
                            </tr>
                            <tr>
                                <td>Stop Bits</td>
                                <td>1</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="programm-content__code">
                    <h4>{t("programmingTitle.1")}</h4>
                </div>

                <CodeBlock language="cpp" 
                    code={`typedef struct {
uint8_t address;
uint8_t cmd;
uint16_t len;
uint8_t data[8];
} CMD_T;`} 
                />
                <div className="programm-content__info">
                    <div className="programm-content__list">
                        <p>{t("programmingT3.1")}</p>
                        <ul>
                            <li>{t("programmingT3.2")}</li>
                            <li>{t("programmingT3.3")}
                                <ul>
                                    <li>{t("programmingT3.4")}</li>
                                    <li>{t("programmingT3.5")}</li>
                                    <li>{t("programmingT3.6")}</li>
                                    <li>{t("programmingT3.7")}</li>
                                </ul>
                            </li>
                            <li>{t("programmingT3.8")}</li>
                            <li>{t("programmingT3.9")}</li>
                        </ul>
                    </div>
                    <div className="programm-content__warning">
                        <p><span>{t("warningSpan")}</span>{t("programmingWarning")}</p>
                    </div>
                </div>
                <CodeBlock language="cpp" 
                    code={`CMD_T transmit_cmd; // Буфер для передачи данных`} 
                />
                <div className="programm-content__title">
                    <p>{t("programmingTitle.2")}</p>
                </div>
                <CodeBlock language="cpp" 
                    code={`transmit_cmd.address = 1; //может быть 1..255
transmit_cmd.len = 0;
transmit_cmd.cmd = 1;
transmitPacket(&transmit_cmd); //передача запроса`} 
                />
                <div className="programm-content__title">
                    <p>{t("programmingTitle.3")}</p>
                </div>
                <CodeBlock language="cpp" 
                    code={`uint8_t revers = 1; // 0 или 1

transmit_cmd.address = 1; //может быть 1..255
transmit_cmd.len = 2;
transmit_cmd.cmd = 20;
*((uint16_t *) &transmit_cmd.data[0]) = (uint16_t)revers;
transmitPacket(&transmit_cmd); //передача запроса`} 
                />
                <div className="programm-content__title">
                    <p>{t("programmingTitle.4")}</p>
                </div>
                <CodeBlock language="cpp" 
                    code={`transmit_cmd.address = 1; //может быть 1..255
transmit_cmd.len = 0;
transmit_cmd.cmd = 21;
transmitPacket(&transmit_cmd); //передача запроса`} 
                />
                <div className="programm-content__title">
                    <p>{t("programmingTitle.5")}</p>
                </div>
                <CodeBlock language="cpp" 
                    code={`transmit_cmd.address = 1; //может быть 1..255
transmit_cmd.len = 0;
transmit_cmd.cmd = 22;
transmitPacket(&transmit_cmd); //передача запроса

CMD_T rxPktQueueBuf; // Буфер для приема данных`} 
                />
                <div className="programm-content__title">
                    <p>{t("programmingTitle.6")}</p>
                    <p>{t("programmingTitle.7")}</p>
                </div>
                <CodeBlock language="cpp" 
                    code={`rxPktQueueBuf.address (из адреса запроса 1..255)
rxPktQueueBuf.len (8) – 4 байта угол + 4 байта температура
rxPktQueueBuf.cmd (1) – датчик возвращает команду с запроса
uint32_t angle = *((uint32_t *) &rxPktQueueBuf.data[0]);
float temper = *((float *) &rxPktQueueBuf.data[4]);`} 
                />
                <ul className='programm-content__ul'>
                    <li>{t("programmingDesc.1")}</li>
                    <li>{t("programmingDesc.2")}</li>
                    <li>{t("programmingDesc.3")}</li>
                    <li>{t("programmingDesc.4")}</li>
                    <li>{t("programmingDesc.5")}</li>
                </ul>
                <CodeBlock language="cpp" 
                    code={`float currentAngle = (float)angle / 1000.0;`} 
                />
                <p>{t("programmingDesc.6")}</p>
                <div className="programm-content__title">
                    <p>{t("programmingTitle.8")}</p>
                    <p>{t("programmingTitle.9")}</p>
                </div>
                <CodeBlock language="cpp" 
                    code={`rxPktQueueBuf.address (из адреса запроса 1..255)
rxPktQueueBuf.len (2)
rxPktQueueBuf.cmd (20) – датчик возвращает команду с запроса
uint16_t revers = *((uint16_t *) &rxPktQueueBuf.data[0]);`} 
                />
                <div className="programm-content__title">
                    <p>{t("programmingTitle.10")}</p>
                </div>
                <CodeBlock language="cpp" 
                    code={`/* На модуле USART–RS485 входы ~RE и DE соединены вместе и подключены к пину № 2, RD и DI 10 и 11 пин соответственно. Кнопка “REVERS” подключена к пину № 3. Кнопка “SET ZERRO” подключена к пину № 4. Кнопка “SAVE” подключена к пину № 5. Вторые концы всех кнопок подключены к общему проводу. Питание датчика подключено к +5V платы Arduino Uno */

#include <SoftwareSerial.h>

SoftwareSerial mySerial(10, 11); // RD, DI

typedef struct{
    uint8_t address;
    uint8_t cmd;
    uint16_t len;
    uint8_t data[8]; // максимальное количестао байт данных
}CMD_T;

CMD_T transmit_cmd;
CMD_T rxPktQueueBuf;
int rw = 2; // pin read/write
int revers = 3; // set revers
int setZerro = 4; // set zerro
int saveData = 5; //save data to sensor

unsigned int setRevers = 0;

void setup() {
    Serial.begin(115200);
    mySerial.begin(115200);
    while (!Serial) {
        ;
    }
    pinMode(rw, OUTPUT);
    pinMode(revers, INPUT_PULLUP);
    pinMode(setZerro, INPUT_PULLUP);
    pinMode(saveData, INPUT_PULLUP);

    digitalWrite (rw, LOW); // Устанавливаем модуль RS-485 на прием

    transmit_cmd.address = 1; // address
    transmit_cmd.cmd = 1; // get angle and temperature
    transmit_cmd.len = 0;
    transmit_cmd.data[0] = 0;
    transmit_cmd.data[1] = 0;
}

void loop() {
    if(digitalRead(revers) == 0){ // нажатие кнопки изменения реверса
        transmit_cmd.cmd = 20;
        transmit_cmd.len = 2;
        setRevers = !setRevers;
        *((unsigned int *) &transmit_cmd.data[0]) = setRevers;
    }else if(digitalRead(setZerro) == 0){ // нажатие кнопки установки нуля
        transmit_cmd.cmd = 21;
        transmit_cmd.len = 0;
    }else if(digitalRead(saveData) == 0){ // нажатие кнопки сохранения данных
        transmit_cmd.cmd = 22;
        transmit_cmd.len = 0;
    }

    while( (digitalRead(revers) == 0) || (digitalRead(setZerro) == 0) ||
        (digitalRead(saveData) == 0)){
            ; // ждем пока не отпустят кнопку команды
    }

    digitalWrite (rw, HIGH); // Устанавливаем модуль RS-485 на передачу
    mySerial.write((byte*)&transmit_cmd, sizeof(transmit_cmd));
    digitalWrite (rw, LOW); // Возвращаем модуль RS-485 на прием
    transmit_cmd.cmd = 1;
    transmit_cmd.len = 0;
    delay(5);

    if (mySerial.readBytes((byte*)&rxPktQueueBuf, sizeof(rxPktQueueBuf))) {
        unsigned long anglel = *((unsigned long *) &rxPktQueueBuf.data[0]);
        float angle = anglel / 1000.0;
        float temperature = *((float *) &rxPktQueueBuf.data[4]);
        Serial.println(angle);
        Serial.println(temperature);
        Serial.println("---------");
    }
}

/* Serial Monitor:
---------
135.04
22.50
---------
135.04
22.50
---------
135.04
22.50
... */`} 
                />

            </div>
        </section>
    );
};

export default SensorProgramming;