import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/HomeAreas.scss';

const HomeAreas = () => {
    const { t } = useTranslation();

    const areasList = [
        {
            id: 0,
            img: "/fields/1.webp",
            title: t("military")
        },
        {
            id: 1,
            img: "/fields/2.webp",
            title: t("medical")
        },
        {
            id: 2,
            img: "/fields/3.webp",
            title: t("industrial")
        },
        {
            id: 3,
            img: "/fields/4.webp",
            title: t("communication")
        },
        {
            id: 4,
            img: "/fields/5.webp",
            title: t("robotics")
        },
        {
            id: 5,
            img: "/fields/6.webp",
            title: t("satcom")
        },
    ]

    return (
        <section className='areas'>
            <div className="areas-content wrapper">
                <div className="areas-content__title">
                    <h4>{t("fields")}</h4>
                </div>
                <div className="areas-content__list">
                    {areasList.map((el, index) => (
                        <div className="areas-content__card" key={index}>
                            <div className="areas-content__card-img">
                                <img src={el.img} alt={el.title} />
                            </div>
                            <h3>{el.title}</h3>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default HomeAreas;