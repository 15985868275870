import React, { useEffect }  from 'react';
import HomeMain from './HomeMain';
import HomeSensors from './HomeSensors';
import HomeInfo from './HomeInfo';
import HomeSteps from './HomeSteps';
import HomeRunningLine from './HomeRunningLine';
import HomeAdvantages from './HomeAdvantages';
import HomeAreas from './HomeAreas';
import Video from './Video';

const Home = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  },);

  return (
    <>
      <HomeMain />
      <HomeSensors />
      <HomeInfo />
      <HomeSteps />
      <HomeRunningLine />
      <HomeAdvantages />
      <HomeAreas />
    </>
  );
};

export default Home;