import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/HomeSteps.scss';
import { Link } from 'react-router-dom';

const HomeSteps = () => {
    const { t } = useTranslation();

    return (
        <section className='steps'>
            <div className="steps-content wrapper">
                <h3>{t("stepsTitle")}</h3>
                <div className="steps-content__bloc">
                    <div className="steps-content__card">
                        <h4>{t("steps")} 1</h4>
                        <h3>{t("step1")}</h3>
                        <img className='steps-content__card-img1' src={process.env.PUBLIC_URL + "/setup/5.webp"} alt="" />
                        <Link to={"/sensor-setup"}>
                            <button className='btn'>
                                <span>{t("readBtn")}</span>
                                <img src={process.env.PUBLIC_URL + "/icons/arrowRight.svg"} alt="read more" />
                            </button>
                        </Link>
                        <img className='steps-content__card-img2' src={process.env.PUBLIC_URL + "/setup/1.webp"} alt="" />
                    </div>
                    <div className="steps-content__cards">
                        <div className="steps-content__cards-small1">
                            <h4>{t("steps")} 2</h4>
                            <h3>{t("step2")}</h3>
                            <Link to={"/computer-connection"}>
                                <button className='btn'>
                                    <span>{t("readBtn")}</span>
                                    <img src={process.env.PUBLIC_URL + "/icons/arrowRight.svg"} alt="read more" />
                                </button>
                            </Link>
                            <img src={process.env.PUBLIC_URL + "/step2.webp"} alt="" />
                        </div>
                        <div className="steps-content__cards-small2">
                            <h4>{t("steps")} 3</h4>
                            <h3>{t("step3")}</h3>
                            <Link to={"/sensor-programming"}>
                                <button className='btn'>
                                    <span>{t("readBtn")}</span>
                                    <img src={process.env.PUBLIC_URL + "/icons/arrowRight.svg"} alt="read more" />
                                </button>
                            </Link>
                            <img src={process.env.PUBLIC_URL + "/step3.svg"} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeSteps;