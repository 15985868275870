import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/Video.scss';

const Video = () => {
    const { t } = useTranslation();

    return (
        <section className='video'>
            <div className="video-content wrapper">
                <div className="video-content__title">
                    <h4>{t("video")}</h4>
                </div>
                <video controls width="100%">
                    <source src={process.env.PUBLIC_URL + "/1.webm"} type="video/webm" />
                    Ваш браузер не підтримує відтворення відео.
                </video>
            </div>
        </section>
    );
};

export default Video;