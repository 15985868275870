import React, { useEffect, useState } from "react";
import { Route, Router, Routes } from "react-router-dom";
import logo from './logo.svg';
import './styles/App.scss';
import Home from "./components/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Video from "components/Video";
import SensorSetup from "components/SensorSetup";
import ComputerConnection from "components/ComputerConnection";
import SensorProgramming from "components/SensorProgramming";
import SensorDraft from "components/SensorDraft";
import Faq from "components/Faq";

const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/sensor/:cardName" element={<SensorDraft />} />
        <Route path="/sensor-setup" element={<SensorSetup />} />
        <Route path="/computer-connection" element={<ComputerConnection />} />
        <Route path="/sensor-programming" element={<SensorProgramming />} />
        <Route path="/faq" element={<Faq />} />
      </Routes>
      <Video />
      <Footer />
    </div>
  );
};

export default App;