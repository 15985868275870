import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from "react-router-dom";
import '../styles/Footer.scss';
import { message } from "antd";
import { sendMessage } from "../api/telegram";

const messages = [
  {
    messageSuccessUa: "Ваше повідомлення успішно надіслано!",
    messageSuccessEn: "Your message was sent successfully!",
    messageSuccessRu: "Ваше сообщение успешно отправлено!",
  },
  {
    messageErrorUa: "Заповніть усі поля!",
    messageErrorEn: "Fill in all fields!",
    messageErrorRu: "Заполните все поля!",
  },
]

const Footer = () => {
  const { t, i18n } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();


  const messageSuccess = () => {
    messageApi.open({
      type: "success",
      content: i18n.language === "ua" ? messages[0].messageSuccessUa : i18n.language === "ru" ? messages[0].messageSuccessRu : messages[0].messageSuccessEn,
      className: "Message",
    });
  };

  const messageError = () => {
    messageApi.open({
      type: "error",
      content: i18n.language === "ua" ? messages[0].messageErrorUa : i18n.language === "ru" ? messages[0].messageErrorRu : messages[0].messageErrorEn,
      className: "Message",
    });
  };

  const [isLoading, setIsLoading] = useState(false);

  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
    email: "",
  });

  const handleChange = (e: any) => {
    const { name, value, files } = e.target;
    if (name === "file" && files.length > 0) {
      setFormValues({
        ...formValues,
        [name]: files[0],
      });
      
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      if (formValues.name && formValues.phone && formValues.email) {
        console.log(formValues.phone);
        const message = `Нове повідомлення: %0A- Ім'я: ${formValues.name} %0A- Номер телефону: ${formValues.phone} %0A- Емейл: ${formValues.email}`;

        await sendMessage(message);
        messageSuccess();
      } else {
        messageError();
      }

    } catch (e) {
      console.log("Error");
    } finally {
      setIsLoading(false);
    }
  };

  function CurrentYear() {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    return <span>{currentYear}</span>;
  }

  return (
    <footer className='footer'>
      {contextHolder}
      <div className="footer-content wrapper">
        <div className="footer-content__section">
          <div className="footer-content__links">
            <div className="footer-content__logo">
              <Link to={"/"} className="footer-content__img">
                <h1>Centroplast</h1>
                <img src={process.env.PUBLIC_URL + "/logo.webp"} alt="Centroplast_logo" />
              </Link>
              <p>{t("footerTxt")}</p>
            </div>
            <nav className="footer-content__nav">
              <ul className="footer-content__ul">
                <li className="footer-content__li">
                  <Link to={"/"} >{t("home")}</Link>
                </li>
                <li className="footer-content__li dropdown-footer">
                  <h4>
                    {t("sensors")}
                    <img className='chevron' src={process.env.PUBLIC_URL + "/icons/chevronDark.svg"} alt="" />
                  </h4>
                  <ul className="dropdown-content-footer">
                    <li><Link to="/sensor/v50">V50</Link></li>
                    <li><Link to="/sensor/v100">V100</Link></li>
                    <li><Link to="/sensor/v150">V150</Link></li>
                    <li><Link to="/sensor/v200">V200</Link></li>
                  </ul>
                </li>
                <li className="footer-content__li dropdown-footer">
                  <h4>
                    {t("settings")}
                    <img className='chevron' src={process.env.PUBLIC_URL + "/icons/chevronDark.svg"} alt="" />
                  </h4>
                  <ul className="dropdown-content-footer">
                    <li><Link to="/sensor-setup">{t("sensorSettings")}</Link></li>
                    <li><Link to="/computer-connection">{t("computerConnection")}</Link></li>
                    <li><Link to="/sensor-programming">{t("sensorProgramming")}</Link></li>
                  </ul>
                </li>
                <li className="footer-content__li">
                  <Link to={"/faq"} >{t("faq")}</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="footer-content__contacts">
            <a 
              className='footer-content__map'
              href="https://maps.app.goo.gl/Hy9xWU7hMykQpJEh8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>{t("footerAddress")}</p>
            </a>
            <a className="footer-content__mail" href="mailto:centroplast.sensor@gmail.com">centroplast.sensor@gmail.com</a>
            <a className="footer-content__tel" href="tel:+380977587990">+38 097 758 79 90</a>

          </div>
          <div className="footer-content__forms">
            <h4>{t("footerForms")}</h4>

            <form 
              onSubmit={handleSubmit}
            >
              <input 
                id="name"
                name="name"
                type="text"
                placeholder={t("footerName")}
                value={formValues.name}
                onChange={handleChange}
                required
              />
              <input 
                id="email"
                name="email"
                type="email"
                placeholder={t("footerEmail")}
                value={formValues.email}
                onChange={handleChange}
                required
              />
              <input 
                id="phone"
                name="phone"
                type="tel"
                placeholder={t("footerPhone")}
                value={formValues.phone}
                onChange={handleChange}
                required
              />
              <button type="submit">
                {t("footerBtn")}
              </button>
            </form>
          </div>
        </div>
        <div className="footer-content__copyright">
          <span className="copyright">
            <CurrentYear /> © Centroplast. All rights reserved.
          </span>
          <span className="copyright">
            Developed by
            <a href="https://abc-digital.com/"> ABC Digital</a>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;