import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import '../styles/Faq.scss';

const Faq = () => {
    const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  },);

    const faqList = [
        {
            id: 0,
            question: t("question.1"),
            answer: t("answer.1")
        },
        {
            id: 1,
            question: t("question.2"),
            answer: t("answer.2")
        },
        {
            id: 2,
            question: t("question.3"),
            answer: t("answer.3")
        },
        {
            id: 3,
            question: t("question.4"),
            answer: t("answer.4")
        },
        {
            id: 4,
            question: t("question.5"),
            answer: t("answer.5")
        },
        {
            id: 5,
            question: t("question.6"),
            answer: t("answer.6")
        },
    
    ]

    return (
        <section className='faq'>
            <div className="faq-content wrapper">
                <h4>{t("faq")}</h4>
                <div className="faq-content__list">
                    {faqList.map((el, index) => (
                        <div className="faq-content__card" key={index}>
                            <div className="faq-content__question">
                                <h5>{el.question}</h5>
                                <img src={process.env.PUBLIC_URL + "/icons/chevronDark.svg"} alt="open" />
                            </div>
                            <div className="faq-content__answer">
                                <p>{el.answer}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Faq;