import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalForm from './UI/ModalForm';
import '../styles/HomeMain.scss';

const HomeMain = () => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <section className='main'>
            <div className="main-content wrapper">
                <div className="main-content__text">
                    <h3>{t("mainSub")}</h3>
                    <h1>{t("mainTitle1")}
                        <span>{t("mainTitle2")}</span>
                    </h1>
                    <button className='btn' onClick={openModal}>
                        <span>{t("mainBtn")}</span>
                        <img src={process.env.PUBLIC_URL + "/icons/arrowRight.svg"} alt="" />
                    </button>
                </div>
                <div className="main-content__img">
                    <img src={process.env.PUBLIC_URL + "/main.webp"} alt="" />
                </div>
            </div>
            <ModalForm isOpen={isModalOpen} onClose={closeModal} />
        </section>
    );
};

export default HomeMain;