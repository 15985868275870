import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/HomeRunningLine.scss';

const HomeRunningLine = () => {
  const { t } = useTranslation();

  return (
    <section className='run'>
        <div className="run__line">
            <div className="run__list">
                <div className="run__slide">
                    Centroplast
                    <img src={process.env.PUBLIC_URL + "/icons/blueArrow.svg"} alt="" />
                    {t("reliably")}
                    <img src={process.env.PUBLIC_URL + "/icons/blueArrow.svg"} alt="" />
                </div>
                <div className="run__slide">
                    Centroplast
                    <img src={process.env.PUBLIC_URL + "/icons/blueArrow.svg"} alt="" />
                    {t("reliably")}
                    <img src={process.env.PUBLIC_URL + "/icons/blueArrow.svg"} alt="" />
                </div>
                <div className="run__slide">
                    Centroplast
                    <img src={process.env.PUBLIC_URL + "/icons/blueArrow.svg"} alt="" />
                    {t("reliably")}
                    <img src={process.env.PUBLIC_URL + "/icons/blueArrow.svg"} alt="" />
                </div>
                <div className="run__slide">
                    Centroplast
                    <img src={process.env.PUBLIC_URL + "/icons/blueArrow.svg"} alt="" />
                    {t("reliably")}
                    <img src={process.env.PUBLIC_URL + "/icons/blueArrow.svg"} alt="" />
                </div>
                <div className="run__slide">
                    Centroplast
                    <img src={process.env.PUBLIC_URL + "/icons/blueArrow.svg"} alt="" />
                    {t("reliably")}
                    <img src={process.env.PUBLIC_URL + "/icons/blueArrow.svg"} alt="" />
                </div>
            </div>
        </div>
    </section>
  );
};

export default HomeRunningLine;