import axios from "axios";

const baseUrl = "https://api.telegram.org/bot7286889355:AAHf5yDzPI5o0DocCKOd-Sv4Fv1QtqbpWd4/";

export const sendMessage = async (message: string): Promise<void> => {
  const url: string = `${baseUrl}sendMessage?chat_id=-1002162207427&text=${message}`;

  console.log(message);
  const response: Response = await fetch(url);

  console.log("response", response);
};