import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/HomeInfo.scss';

const HomeInfo = () => {
    const { t } = useTranslation();

    return (
        <section className='about'>
            <div className="about-content wrapper">
                <h1 className="about-content__title">{t("mainTitle1")}{t("mainTitle2")}</h1>
                <p className="about-content__info">
                    {t("homeInfo1")}
                    <br />
                    <br />
                    {t("homeInfo2")}
                </p>
            </div>
        </section>
    );
};

export default HomeInfo;