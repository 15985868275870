import React, { useState } from 'react';
import Prism from 'prismjs';
import '../../styles/CodeBlock.scss';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

const CodeBlock = ({ language, code }) => {
  const [copy, setCopy] = useState(false);

  return (
    <div className="code-container">
      <div className="code-container__content">
        <div className="code-header">
          <p>C</p>
          {copy ? (
            <button className="copy-button" >
              <img src={process.env.PUBLIC_URL + "/icons/copyIcon.svg"} alt="" />
              Copied!
            </button>
          ) : (
            <button 
              className="copy-button" 
              onClick={ () => {
                navigator.clipboard.writeText(code);
                setCopy(true);
                setTimeout(() => {
                  setCopy(false);
                }, 30000);
              }}
            >
              <img src={process.env.PUBLIC_URL + "/icons/copyCode.svg"} alt="" />
              Copy code
            </button>
          )}
        </div>
        <SyntaxHighlighter 
          language="cpp" 
          style={atomOneDark} 
          customStyle={{
            padding: '20rem'
          }}
          wrapLongLines={true}
        >
          {code}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

export default CodeBlock;