import React, { useState, ChangeEvent, FormEvent } from 'react';
import '../../styles/ModalForm.scss';
import { useTranslation } from 'react-i18next';
import { message } from "antd";
import { sendMessage } from "../../api/telegram";

const messages = [
    {
        messageSuccessUa: "Ваше повідомлення успішно надіслано!",
        messageSuccessEn: "Your message was sent successfully!",
        messageSuccessRu: "Ваше сообщение успешно отправлено!",
    },
    {
        messageErrorUa: "Заповніть усі поля!",
        messageErrorEn: "Fill in all fields!",
        messageErrorRu: "Заполните все поля!",
    },
]

interface ModalFormProps {
    isOpen: boolean;
    onClose: () => void;
}

interface FormData {
    name: string;
    phone: string;
    email: string;
}

const ModalForm: React.FC<ModalFormProps> = ({ isOpen, onClose }) => {
    const { t, i18n } = useTranslation();
    const [messageApi, contextHolder] = message.useMessage();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const messageSuccess = () => {
        messageApi.open({
            type: "success",
            content: i18n.language === "ua" ? messages[0].messageSuccessUa : i18n.language === "ru" ? messages[0].messageSuccessRu : messages[0].messageSuccessEn,
            className: "Message",
        });
    };
    
    const messageError = () => {
        messageApi.open({
            type: "error",
            content: i18n.language === "ua" ? messages[0].messageErrorUa : i18n.language === "ru" ? messages[0].messageErrorRu : messages[0].messageErrorEn,
            className: "Message",
        });
    };

    const [isLoading, setIsLoading] = useState(false);

    const [formValues, setFormValues] = useState({
        name: "",
        phone: "",
        email: "",
    });

    const handleChange = (e: any) => {
        const { name, value, files } = e.target;
        if (name === "file" && files.length > 0) {
            setFormValues({
                ...formValues,
                [name]: files[0],
            });

        } else {
            setFormValues({
                ...formValues,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        setIsSubmitting(true);

        setTimeout(() => {
            setIsSubmitting(false);
            setIsSubmitted(true);
        }, 2000);
    
        try {
            setIsLoading(true);
            if (formValues.name && formValues.phone && formValues.email) {
                console.log(formValues.phone);
                const message = `Нове повідомлення: %0A- Ім'я: ${formValues.name} %0A- Номер телефону: ${formValues.phone} %0A- Емейл: ${formValues.email}`;
    
                await sendMessage(message);
                messageSuccess();
            } else {
                messageError();
            }
    
        } catch (e) {
            console.log("Error");
        } finally {
            setIsLoading(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            {contextHolder}
            <div className="modal-content">
                <button className="modal-close" onClick={onClose}>&times;</button>
                <h2>{t("footerForms")}</h2>
                {!isSubmitted ? (
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <input
                                type="text"
                                name="name"
                                placeholder={t("footerName")}
                                value={formValues.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="email"
                                name="email"
                                placeholder={t("footerEmail")}
                                value={formValues.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="tel"
                                name="phone"
                                placeholder={t("footerPhone")}
                                value={formValues.phone}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <button type="submit" className="submit-btn">
                            {isSubmitting ? t("formSubmitting") : t("formSubmit")}
                        </button>
                    </form>
                ) : (
                    <div className="form-success">
                        <p>{t("formSuccessMessage")}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ModalForm;