import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

// Імпорт файлів з перекладами
import translationEN from './locales/en/translation.json';
import translationUA from './locales/ua/translation.json';
import translationRU from './locales/ru/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  ua: {
    translation: translationUA,
  },
  ru: {
    translation: translationRU,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    debug: true,
    resources,
    lng: 'ua', // мова за замовчуванням
    fallbackLng: 'ua', // мова для відображення, якщо вибрана мова відсутня
    returnObjects: true,
    interpolation: {
      escapeValue: false, // React вже автоматично екранує значення
    },
  });

export default i18n;