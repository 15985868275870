import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/HomeAdvantages.scss';

const HomeAdvantages = () => {
    const { t } = useTranslation();

    return (
        <section className='advantages'>
            <div className="advantages__blur">
                <div className="advantages-content wrapper">
                    <div className="advantages-content__col1">
                        <div className="advantages-content__header">
                            <h4>{t("adventages")}</h4>
                            <div className="advantages-content__header-bloc">
                                <img src={process.env.PUBLIC_URL + "/icons/stampIcon.svg"} alt="" />
                                <div className="advantages-content__header-plus">
                                    <h3>50+</h3>
                                    <p>{t("countries")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="advantages-content__txt">
                            <p>{t("adventageTxt")}</p>
                        </div>
                    </div>
                    <div className="advantages-content__col2">
                        <div className="advantages-content__list">
                            <div className="advantages-content__card">
                                <img src={process.env.PUBLIC_URL + "/icons/walletIcon.svg"} alt="" />
                                <ul>
                                    <li>{t("advList1")}</li>
                                    <li>{t("advList2")}</li>
                                </ul>
                            </div>
                            <div className="advantages-content__card">
                                <img src={process.env.PUBLIC_URL + "/icons/clockIcon.svg"} alt="" />
                                <ul>
                                    <li>{t("advList3")}</li>
                                    <li>{t("advList4")}</li>
                                </ul>
                            </div>
                            <div className="advantages-content__card">
                                <img src={process.env.PUBLIC_URL + "/icons/starsIcon.svg"} alt="" />
                                <ul>
                                    <li>{t("advList5")}</li>
                                    <li>{t("advList6")}</li>
                                </ul>
                            </div>
                            <div className="advantages-content__card">
                                <img src={process.env.PUBLIC_URL + "/icons/starsIcon.svg"} alt="" />
                                <ul>
                                    <li>{t("advList7")}</li>
                                    <li>{t("advList8")}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeAdvantages;