import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import '../styles/HomeSensors.scss';

const HomeSensors = () => {
    const { t, i18n } = useTranslation();

    return (
        <section className='sensors'>
            <div className="sensors-content wrapper">
                <h1>{t("mainSensorTitle")}</h1>
                <div className="sensors-content__list">
                    {sensorsSize.map((sensor, index) => (
                        <Link 
                            to={`/sensor/${sensor.name.toLowerCase()}`} 
                            state={{ sensorData: sensor }} 
                            className="sensors-content__card" 
                            key={index}
                        >
                            <img src={process.env.PUBLIC_URL + sensor.img} alt={sensor.name} />
                            <h2>{i18n.language === "en" ? sensor.titleEN : i18n.language === "ua" ? sensor.titleUA : sensor.titleRU}</h2>
                        </Link>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default HomeSensors;

const sensorsSize = [
    {
        id: 0,
        img: "/draft/v50/rot_v50.webp",
        titleEN: "Sensor V50",
        titleUA: "Датчик V50",
        titleRU: "Датчик V50",
        name: "V50"
    },
    {
        id: 1,
        img: "/draft/v100/rot_v100.webp",
        titleEN: "Sensor V100",
        titleUA: "Датчик V100",
        titleRU: "Датчик V100",
        name: "V100"
    },
    {
        id: 2,
        img: "/draft/v150/rot_v150.webp",
        titleEN: "Sensor V150",
        titleUA: "Датчик V150",
        titleRU: "Датчик V150",
        name: "V150"
    },
    {
        id: 3,
        img: "/draft/v200/rot_v200.webp",
        titleEN: "Sensor V200",
        titleUA: "Датчик V200",
        titleRU: "Датчик V200",
        name: "V200"
    },
]