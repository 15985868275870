import React, { useState } from 'react';
import '../styles/Header.scss';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import LanguageSelector from './UI/languageSelector';

const Header = () => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className='header'>
      <div className="header-settings">
        <div className="header-settings__assets wrapper">
          <div className="header-settings__lang dropdown">
            <button className="dropbtn">
              <img src={process.env.PUBLIC_URL + t("flag")} alt="" />
              {t("switcher")}
              <img className='chevron' src={process.env.PUBLIC_URL + "/icons/chevronWhite.svg"} alt="dropdown" />
            </button>
            <div className="dropdown__content">
              <LanguageSelector />
            </div>
          </div>
          <div className="header-settings__call">
            <span>
              {t("call")}:
              <a href="tel:+380977587990"> +38 097 758 79 90</a>
            </span>
          </div>
          <div className="header-settings__email">
            <span>
              {t("email")}:
              <a href="mailto:centroplast.sensor@gmail.com"> centroplast.sensor@gmail.com</a>
            </span>
          </div>
        </div>
      </div>
      <div className="header-nav">
        <div className="header-nav__logo">
          <Link to={"/"} className="header-nav__logo-link">
            <h1>Centroplast</h1>
            <img src={process.env.PUBLIC_URL + "/logo.webp"} alt="Centroplast_logo" />
          </Link>
        </div>
        <div className={`header-nav__menu ${isMenuOpen ? 'open' : ''}`}>
          <button className="header-nav__burger" onClick={toggleMenu}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </button>
          <nav className="header-nav__nav">
            <ul className="header-nav__ul">
              <li className="header-nav__li">
                <Link to={"/"}>{t("home")}</Link>
              </li>
              <li className="header-nav__li dropdown">
                <h4>
                  {t("sensors")}
                  <img className='chevron' src={process.env.PUBLIC_URL + "/icons/chevronDark.svg"} alt="" />
                </h4>
                <ul className="dropdown-content">
                  <li><Link to="/sensor/v50">V50</Link></li>
                  <li><Link to="/sensor/v100">V100</Link></li>
                  <li><Link to="/sensor/v150">V150</Link></li>
                  <li><Link to="/sensor/v200">V200</Link></li>
                </ul>
              </li>
              <li className="header-nav__li dropdown">
                <h4>
                  {t("settings")}
                  <img className='chevron' src={process.env.PUBLIC_URL + "/icons/chevronDark.svg"} alt="" />
                </h4>
                <ul className="dropdown-content">
                  <li><Link to="/sensor-setup">{t("sensorSettings")}</Link></li>
                  <li><Link to="/computer-connection">{t("computerConnection")}</Link></li>
                  <li><Link to="/sensor-programming">{t("sensorProgramming")}</Link></li>
                </ul>
              </li>
              <li className="header-nav__li">
                <Link to={"/faq"}>{t("faq")}</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;