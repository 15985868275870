import React from "react";
import { changeLanguage } from "i18next";
import { useTranslation } from "react-i18next";
import '../../styles/LanguageSelector.scss';

interface Language {
    code: string;
    lang: string;
    flag: string;
}

const languages: Language[] = [
    {code: "en", lang: "English", flag: "/flags/usa.svg"},
    {code: "uk", lang: "Українська", flag: "/flags/ukraine.svg"},
    {code: "ru", lang: "Русский", flag: "/flags/russia.svg"}
];

const LanguageSelector: React.FC = () => {
    const {i18n} = useTranslation();

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    const {t} = useTranslation()

    return (
        <div className="header__btn dropdown">
            {languages.map((lng) => {
                return (
                    <>
                        
                        <button 
                            className={lng.code === i18n.language ? "selected" : ""} 
                            key={lng.code} 
                            onClick={() => changeLanguage(lng.code)}
                        >
                            {lng.lang}
                        </button>
                    </>
                )
            })}
        </div>
    );
};

export default LanguageSelector;